export const languages = [
    { value: 'javascript', label: 'JavaScript' },
    { value: 'typescript', label: 'TypeScript' },
    { value: 'java', label: 'Java' },
    { value: 'go', label: 'Go' },
    { value: 'html', label: 'HTML' },
    { value: 'php', label: 'PHP' },
    { value: 'C%2B%2B', label: 'C++' },
    { value: 'rust', label: 'Rust' },
    { value: 'C%23', label: 'C#' },
]

export const labels = [
    { value: 'help+wanted', label: 'Help Wanted' },
    { value: 'beginner+friendly', label: 'Beginner Friendly' },
    { value: 'enhancement', label: 'Enhancement' },
    { value: 'bug', label: 'Bug' },
    { value: 'good+first+issue', label: 'Good First Issue' },
    { value: 'hacktoberfest', label: 'Hacktoberfest' },
]