import React from 'react'
import styled from '@emotion/styled'
import Select from 'react-select';
import { FaStar } from "react-icons/fa";

const Filters = ({ language, languages, handleLanguage, label, labels, handleLabel, showFav, setShowFav, favIssues }) => {
    return (
        <FiltersWrapper>
            <FilterSelect
                className='filter__container'
                classNamePrefix="filter"
                value={language.value}
                options={languages}
                onChange={handleLanguage}
                placeholder={`Language: ${language.label}`}
            />
            <FilterSelect
                className='filter__container'
                classNamePrefix="filter"
                value={label.value}
                options={labels}
                onChange={handleLabel}
                placeholder={`Label: ${label.label}`}
            />
            <FilterFavorite onClick={() => setShowFav(!showFav)} className={favIssues.length >= 1 ? "fav--active" : ""}>
                <FaStar />
            </FilterFavorite>
        </FiltersWrapper>
    )
}

export default Filters

// Component Styles 
const FiltersWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--black);
    border-top: var(--md) solid var(--black);
    border-bottom: 2px solid var(--gray);
    padding-bottom: var(--md);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 600px) {
        flex-flow: column;
    }
`

// Class names are generated via React Select
const FilterSelect = styled(Select)`
    &.filter__container {
        width: 100%;
        margin-right: var(--md);
    }
    .filter__control {
        background: var(--black);
        border: 2px solid var(--green);
        border-radius: var(--xxs);
        color: var(--white);
        cursor: pointer;
    }
    .filter__placeholder {
        color: var(--gray);
    }
    .filter__single-value, .filter__input {
        color: var(--white);
    }
    .filter__indicator-separator {
        background-color: var(--gray);
        width: 3px;
    }
    .filter__dropdown-indicator  {
        color: var(--sky);
    }
    .filter__menu {
        background: var(--black) !important;
        border: 3px solid var(--green) !important;
        border-radius: var(--xxs) !important; 
        color: var(--white) !important;
        margin-top: var(--xs) !important;
    }
    .filter__option:hover, .filter__option--is-selected, .filter__option--is-focused {
        color: var(--black) !important;
        background-color: var(--green) !important;
        background-image: var(--gradient) !important;
        cursor: pointer;
        font-weight: 700;
    }
    .filter__favorite {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--xxs);
        height: 38px;
        min-width: 38px;
        background-color: var(--gray);
        font-size: var(--md);
        cursor: pointer;
        :hover, &.filter__favorite--active {
            background-color: var(--green);
            background-image: var(--gradient);
        }
    }
    @media (max-width: 600px) {
        &.filter__container {
            margin-right: 0;
            margin-bottom: var(--md);
        }
    }
`

const FilterFavorite = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--xxs);
    height: 38px;
    min-width: 38px;
    background-color: var(--gray);
    font-size: var(--md);
    cursor: pointer;
    :hover, &.fav--active {
        background-color: var(--green);
        background-image: var(--gradient);
    }
`