import React from 'react'
import styled from '@emotion/styled'
import moment from "moment"
import { FaRocket, FaStar } from "react-icons/fa";
import { PulseLoader } from 'react-spinners';

const Issue = ({ url, repo, info, number, justLaunched, issue, addToFav }) => {
    return (
        <IssueWrapper>
            <IssueContent>
                <h2>{repo}</h2>
                <h3>Issue #{number}</h3>
                <p>{info}</p>
                <p><a href={url} target="_blank" rel="noopener noreferrer">View on Github</a></p>
                <span className="favorite" onClick={() => addToFav(issue)}><FaStar /></span>
                {justLaunched &&
                    <span className="launched"><FaRocket /></span>
                }
            </IssueContent>
        </IssueWrapper>
    )
}

const Issues = ({ issues, isLoading, page, setPage, addToFav }) => {
    const getRepoName = (repo) => {
        let title = repo.split('/').slice(-1).join()
        if (title.length >= 15) {
            return title.substr(0, 15) + '...'
        } else {
            return title
        }
    }

    const getIssueExcerpt = (info) => {
        return info.substr(0, 50) + '...'
    }

    const isIssueNew = (time) => {
        return moment(time).isAfter(moment().subtract(1, 'hours'))
    }

    return (
        <>
            {isLoading ? (
                <LoadingWrapper>
                    <PulseLoader
                        sizeUnit={"rem"}
                        size={1}
                        color={'#27e27c'}
                    />
                </LoadingWrapper>
            ) : (
                    <>
                        <IssuesWrapper>
                            {issues.map((issue) => {
                                return (
                                    <Issue
                                        key={issue.id}
                                        url={issue.html_url}
                                        repo={getRepoName(issue.repository_url)}
                                        info={getIssueExcerpt(issue.title)}
                                        number={issue.number}
                                        justLaunched={isIssueNew(issue.created_at)}
                                        addToFav={addToFav}
                                        issue={issue}
                                    />
                                )
                            })}
                        </IssuesWrapper>
                        <IssuesNav>
                            {page >= 2 &&
                                <button onClick={() => setPage(page - 1)}>Previous</button>
                            }
                            {issues.length >= 30 &&
                                <button onClick={() => setPage(page + 1)}>Next</button>
                            }
                        </IssuesNav>
                    </>
                )
            }
        </>
    )
}

export default Issues

// Component Styles
const LoadingWrapper = styled.div`
    margin: var(--md) auto 0;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
`

const IssuesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: var(--md);
    margin-top: var(--md);
`

const IssuesNav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--md);
    button {
        padding: var(--xxs) var(--lg);
        border-radius: var(--xxs);
        background-color: var(--green);
        background-image: var(--gradient);
        font-weight: 700;
        cursor: pointer;
        :hover {
            background: var(--sky);
        }
    }
`


const IssueWrapper = styled.div`
    position: relative;
    padding: var(--md) var(--sm);
    background: var(--white);
    border: 3px solid var(--green); 
    border-radius: var(--xxs); 
    text-align: center;
`

const IssueContent = styled.div`
    h2 {
        font-size: var(--md);      
    }
    h3 {
        color: var(--gray);
        font-size: var(--xs);
    }
    p {
        margin-top: var(--sm);
    }
    a {
        color: var(--sky);
        font-weight: 700;
        :hover {
            color: var(--black);
        }
    }
    span {
        position: absolute;
        top: calc((var(--xxs)) - 2px);
        font-size: var(--sm);
        &.favorite {
            right: var(--xxs);
            color: var(--gray);
            cursor: pointer;
            :hover {
                color: var(--sky);
            }        
        }
        &.launched {
            left: var(--xxs);
            color: var(--sky);
        }
    }
`