import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { languages, labels } from "../data/filters"
import useLocalStorage from "../hooks/useLocalStorage"
import cogoToast from 'cogo-toast';
import Filters from "../components/filters"
import Favorites from "../components/favorites"
import Issues from "../components/issues"

const IndexPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [issues, setIssues] = useState([])
  const [language, setLanguage] = useState(languages[0])
  const [label, setLabel] = useState(labels[0])
  const [favIssues, setFavIssues] = useLocalStorage('Favorite Issues', [])
  const [showFav, setShowFav] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    async function getIssues() {
      setIsLoading(true)
      const res = await fetch(
        `https://api.github.com/search/issues?page=${page}&q=language:${language.value}+label:"${label.value}"+type:issue+state:open`
      )
      const issues = await res.json();
      setIssues(issues.items)
      window.scroll(0, 0);
      setIsLoading(false)
    }
    getIssues();
  }, [language, label, page]);

  const handleLanguage = (value) => {
    setLanguage(value)
    setPage(1)
  }

  const handleLabel = (value) => {
    setLabel(value)
    setPage(1)
  }

  const addToFav = (issue) => {
    let favorites = [...favIssues, issue]
    setFavIssues(favorites)
    cogoToast.success("Issue added to favorites.", { position: 'bottom-right', bar: { color: '#00d3ce' } });
  }

  const removeFromFav = (issue) => {
    let favorites = [...favIssues]
    let issueIndex = favorites.indexOf(issue)
    favorites.splice(issueIndex, 1)
    setFavIssues(favorites)
    cogoToast.success("Issue removed from favorites.", { position: 'bottom-right', bar: { color: '#00d3ce' } });
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Filters
        language={language}
        languages={languages}
        handleLanguage={handleLanguage}
        label={label}
        labels={labels}
        handleLabel={handleLabel}
        showFav={showFav}
        setShowFav={setShowFav}
        favIssues={favIssues}
      />
      {showFav &&
        <Favorites
          favIssues={favIssues}
          removeFromFav={removeFromFav}
        />
      }
      <Issues
        isLoading={isLoading}
        issues={issues}
        addToFav={addToFav}
        page={page}
        setPage={setPage}
      />
    </Layout>
  )
}


export default IndexPage
