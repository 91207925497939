import React from 'react'
import styled from '@emotion/styled'
import { TiDelete } from "react-icons/ti";

const Favorites = ({ removeFromFav, favIssues }) => {
    return (
        <FavoritesWrapper>
            <h2>Favorites:</h2>
            {favIssues.length >= 1 ? (
                <ul>
                    {favIssues.map((issue) => {
                        return (
                            <li key={issue.id}><a href={issue.html_url} target="_blank" rel="noopener noreferrer">{issue.html_url}</a><span onClick={() => removeFromFav(issue)}><TiDelete /></span></li>
                        )
                    })}
                </ul>
            ) :
                (
                    <p>It looks like you don't have any favorites. Click the star icon on any issue and save it for later.</p>
                )
            }
        </FavoritesWrapper>
    )
}

export default Favorites

// Component Styles 
const FavoritesWrapper = styled.section`
    border-bottom: 2px solid var(--gray);
    padding-bottom: var(--md);
    margin: var(--md) 0;
    h2 {
        color: var(--white);
        font-size: var(--md);
        margin-bottom: var(--xxs);
    }
    li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: var(--xxs) 0 var(--xxs);
        font-size: var(--xs);
    }
    a {
        color: var(--sky);
        :hover {
            color: var(--green);
        }
    }
    span {
        display: flex;
        align-items: center;
        color: var(--gray);
        margin-left: var(--xxs);
        font-size: var(--sm);
        cursor: pointer;
        :hover {
            color: var(--white);
        }
    }
    p {
        color: var(--gray);
        margin-bottom: var(--xxs);
    }
`